<template>
  <v-navigation-drawer class="bg-ingco" :width="navigation.width" :mini-variant.sync="drawers" app permanent
    ref="drawer">
    <v-toolbar prominent height="35" dense collapse src="@/assets/ingcoph_bg/ingcoph_logo_alt.jpg">
    </v-toolbar>
    <v-list dense>
      <v-list-group v-for="item in SIDENAV_LOAD" :key="item.menubar_desc" no-action color="white">
        <template v-slot:activator dense>
          <div class="px-2">
            <v-icon size="16" color="white">{{ item.icon }}</v-icon>
          </div>
          <v-list-item-content>
            <v-list-item-title class="link_color text-wrap">
                {{item.menubar_desc}}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(child, i) in item.sidebarlinks" :key="i" link :to="{
          name: child.route_name,
          params: { id: child.link_id,route_company:child.route_company },
        }" class="text-decoration-none">


          <v-list-item-content>
            <v-list-item-title :class="
              'text-decoration-none link_color '
            ">{{ child.menu_link_desc }}
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  props: ["drawers"],
  data() {
    return {
      group: true,
      navigation: {
        shown: false,
        width: 200,
        borderSize: 4,
      },
    };
  },
  computed: {
    ...mapGetters([
      "NEW_DEPARTMENT",
      "GET_SUBMIT_SUCCESS",
      "USERACCOUNT_LOAD",
      "LINK_ACTIVE",
      "SIDENAV_LOAD"
    ]),
  },
  methods: {
    active_bg(id) {
      if (this.LINK_ACTIVE == id) {
        return "text-decoration-none v-list-item--active theme--light";
      }else{
        this.$store.commit("LINK_ACTIVE", this.$router.app._route.params.id);
        return "";
      }
    },
    route_link(child) {
      let a = document.createElement("a");
      let link = this.$router.resolve({
        name: child.route_name,
        params: { id: child.link_id },
      });
      location.href = window.location.origin + "/" + link.href;
    },
    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
      i.style.backgroundColor = "#17468f";
    },
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },
  },
  mounted() {
    this.active_bg("");
    this.setBorderWidth();
    this.setEvents();
  },
  watch: {
    GET_SUBMIT_SUCCESS: {
      handler(val) {
        this.$store.commit("ROLE_ACCESS_LOAD", "");
        this.$store.commit("DIALOG", false);
        this.$store.dispatch("credentials");
        Swal.fire({
          title: "Control has been saved",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        this.$store.commit("SUBMIT_SUCCESS", false);
      },
    },
    NEW_DEPARTMENT: {
      handler(val) {
        this.$store.dispatch("credentials");
        // this.$store.dispatch("getAllDepartment");
        // this.$store.commit("NEW_DEPARTMENT", false);
      },
    },
    group() {
      this.drawers = false;
    },
    drawers(val) {
      if (!val) {
        this.navigation.width = 200;
      }
    },
  },
};
</script>
<style scoped>
.v-application .primary--text {
  color: #000001 !important;
  caret-color: #000000 !important;
}

.active_link {
  background-color: #1e1e1c !important;
  color: white;
}

.active_text {
  color: #ffffff !important;
}

.link_color {
  color: #ffffff;
}
</style>
